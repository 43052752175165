{
  "name": "iskymarshal",
  "version": "0.1.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "dev": "ng serve --configuration development",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.0",
    "@angular/common": "^18.2.0",
    "@angular/compiler": "^18.2.0",
    "@angular/core": "^18.2.0",
    "@angular/forms": "^18.2.0",
    "@angular/platform-browser": "^18.2.0",
    "@angular/platform-browser-dynamic": "^18.2.0",
    "@angular/platform-server": "^18.2.0",
    "@angular/router": "^18.2.0",
    "@angular/ssr": "^18.2.0",
    "ag-grid-angular": "^32.0.2",
    "ag-grid-community": "^32.0.2",
    "express": "^4.18.2",
    "luxon": "^3.4.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.0",
    "@angular/cdk": "^18.2.0",
    "@angular/cli": "^18.2.0",
    "@angular/compiler-cli": "^18.2.0",
    "@tailwindcss/aspect-ratio": "^0.4.2",
    "@tailwindcss/forms": "^0.5.7",
    "@tailwindcss/typography": "^0.5.10",
    "@types/express": "^4.17.17",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.14.201",
    "@types/luxon": "^3.3.4",
    "@types/node": "^20.11.17",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lodash": "^4.17.21",
    "postcss": "^8.4.38",
    "postcss-import": "^16.1.0",
    "prettier": "^3.3.2",
    "tailwind-scrollbar": "^3.0.5",
    "tailwindcss": "^3.4.4",
    "tailwindcss-debug-screens": "^2.2.1",
    "typescript": "^5.3.3"
  }
}
