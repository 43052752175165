import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, from, map, of, tap } from "rxjs";

import { InformationStarSystem } from "../interfaces/information-star-system";

@Injectable({
  providedIn: 'root'
})
export class InformationStarSystemService {
  issHash!: { [key: string]: InformationStarSystem };
  informationStarSystems!: InformationStarSystem[];
  raceId!: string;
  dataState: string;

  constructor(private http: HttpClient) {
    this.dataState = "empty";
  };

  processInformationStarSystem(raceId: string, informationStarSystem: InformationStarSystem) {
    localStorage.setItem('iss' + raceId + informationStarSystem.starSystemId, JSON.stringify(informationStarSystem));
  };

  processInformationStarSystems(raceId: string, informationStarSystems: InformationStarSystem[]) {
    this.informationStarSystems = informationStarSystems;
    localStorage.setItem('informationStarSystems' + raceId, JSON.stringify(informationStarSystems));
    for (const informationStarSystem of informationStarSystems) {
      localStorage.setItem('iss' + raceId + informationStarSystem.starSystemId, JSON.stringify(informationStarSystem));
    }
    this.dataState = "fetched";
  };

  getInformationStarSystemForRaceId(raceId: string): Observable<InformationStarSystem[]> {
    // let cachedIss = localStorage.getItem('informationStarSystems' + raceId);
    // if (cachedIss) {
    //   let iss: InformationStarSystem[] = JSON.parse(cachedIss);
    //   return of<InformationStarSystem[]>(iss);
    // }
    return this.loadInformationStarSystemForRaceId(raceId);
  };

  loadInformationStarSystemForRaceId(raceId: string): Observable<InformationStarSystem[]> {
    this.dataState = "fetching";
    return this.http.get<InformationStarSystem[]>('/api/informationStarSystem/raceId/' + raceId)
      .pipe(
        tap(iss => this.processInformationStarSystems(raceId, iss)),
        map(iss => iss)
      )
  };

  getInformationStarSystemForRaceAndStarSystemId(raceId: string, starSystemId: string) {
    if (this.dataState === 'empty') {
      throw new Error('InformationStarSystem data has not been fetched');
    }
    else if (this.dataState === 'fetched') {
      let issRaw = localStorage.getItem('iss' + raceId + starSystemId);
      if (issRaw) {
        return JSON.parse(issRaw);
      }
      throw new Error('InformationStarSystem does not have data for starSystemId: ' + starSystemId);
    }
    else {
      // loop until data is fetched and processed
      throw new Error('InformationStarSystem dataState == ' + this.dataState);
    }
    throw new Error('InformationStarSystem dataState is unknown');
  };
}

//OLD AngularJS
/* globals angular localStorage */
// angular.module('app').controller('StarSystemDisplayCtrl', [
//     '$timeout', 'DataStoreSvc', 'StarSystemSvc', 'InformationPopulationSvc',
//     function ($timeout, DataStoreSvc, StarSystemSvc, InformationPopulationSvc) {
//         'use strict';

//         var ssdc = this;
//         var localData = {};
//         ssdc.starSystemDisplayData = localData;
//         ssdc.session = DataStoreSvc;

//         let getStarSystemPromise = function (starSystemId) {
//             let starSystemPromise = DataStoreSvc.getStarSystem();
//             if (starSystemPromise == null) {
//                 starSystemPromise = StarSystemSvc.getStarSystemAdmin(starSystemId);
//             }
//             starSystemPromise.then( function (results) {
//                 $timeout( function () {
//                     ssdc.assignStarSystem(results);
//                     DataStoreSvc.setStarSystem(null);
//                 });
//                 return "Done";
//             });
//             return starSystemPromise;
//         };

//         let getPopulationPromise = function (starSystemId) {
//             let populationPromise = InformationPopulationSvc.getAllInformationPopulationForStarSystemId(starSystemId);
//             return populationPromise.then(function (populationData) {
//                 let populations = {};
//                 for (const population of populationData) {
//                     let popArray = populations[population.planetId] || [];
//                     popArray.push(population);
//                     populations[population.planetId] = popArray;
//                 }
//                 $timeout(function () {
//                     localData.populations = populations;
//                 });
//                 return "Done";
//             })
//         };

//         let getRacePromise = function () {
//             let races = JSON.parse(localStorage.getItem('races'));
//             let racesPromise = (races) ? Promise.resolve(races) : RaceSvc.getAllRacesForCampaign(ssdc.session.campaign._id);
//             racesPromise.then( function (races) {
//                 localData.raceHash = races.reduce(function(dictionary, race) {
//                     dictionary[race._id] = race;
//                     return dictionary;
//                 }, {});
//             })
//         };

//         var init = function () {
//             localData.campaign = DataStoreSvc.getCampaign();
//             ssdc.changeStarSystemId(DataStoreSvc.getStarSystemId());
//         };

//         ssdc.changeStarSystemId = function (starSystemId) {
//             localData.starSystemId = starSystemId;
//             DataStoreSvc.setStarSystemId(starSystemId);
//             let starSystemPromise = getStarSystemPromise(starSystemId);
//             let populationPromise = getPopulationPromise(starSystemId);
//             let racePromise = getRacePromise();
//             Promise.all([starSystemPromise, populationPromise, racePromise]).then(
//                 function (results) {
//                     $timeout(function () {
//                         console.log('controller: ssdc: ', ssdc);
//                     });
//                 }
//             );
//         };

//         ssdc.assignStarSystem = function (results) {
//             localData.starSystem = results;
//             localData.starSystemId = results._id;
//         };

//         init();
//     }
// ]);
